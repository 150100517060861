import React from 'react';
import { useTranslation } from 'react-i18next';

export const ButtonLarge = () => {
  const { t } = useTranslation();

  return (
    <button className="button-large" data-umami-event="Click CTA Large">
      {t('cta')}
    </button>
  );
};
