import React, { useEffect } from 'react';
import { MainBanner } from '../components/main-banner/MainBanner';
import { WeAre } from '../components/we-are/WeAre';
import { AboutOurProjects } from '../components/projects/AboutOurProjects';
import { PreviousProjects } from '../components/projects/PreviousProjects';
import { MainTeam } from '../components/WhoIs/MainTeam';
import { SupportedBy } from '../components/WhoIs/SupportedBy';
import { ContactUs } from '../components/Contact/ContactUs';
import { useLocation } from 'react-router-dom';

export const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if there's a scrollToComponent state
    if (location.state?.scrollToComponent) {
      const component = location.state.scrollToComponent;
      const element = document.getElementById(component);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="font-gordita">
      <MainBanner />
      <WeAre />
      <AboutOurProjects />
      <PreviousProjects />
      <MainTeam />
      <SupportedBy />
      <ContactUs />
    </div>
  );
};
