export const ParticipantColor = ({ data, isNew, refresh }: any) => {
  return (
    <div className="flex flex-col lg:flex-row w-full lg:gap-x-12 py-6 ">
      <div>
        <img
          className="w-[130px] h-[130px] aspect-square bg-main-color overflow-hidden object-cover object-top rounded-lg m-auto lg:m-0 mb-4 lg:mb-0"
          src={data.img}
          alt={data.name}
        />
      </div>
      <div className="flex flex-col place-content-center grid">
        <div>
          <div className="font-semibold">{data.name}</div>
          <div className="w-[350px]">{data.position}</div>
        </div>
      </div>
    </div>
  );
};
