import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchProjects = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/projects?sort=desc`);
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const updateProject = async (memberId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/projects/${memberId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating Project:', error);
    throw error;
  }
};

export const addProject = async updatedData => {
  try {
    const response = await axios.post(`${API_BASE_URL}/projects/`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error adding Project', error);
    throw error;
  }
};

export const fetchMainTeam = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/mainteam`);
    return response.data;
  } catch (error) {
    console.error('Error fetching MainTeam:', error);
    throw error;
  }
};

export const updateMainTeamMember = async (memberId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/mainteam/${memberId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating MainTeam member:', error);
    throw error;
  }
};

export const addMainTeamMember = async updatedData => {
  try {
    const response = await axios.post(`${API_BASE_URL}/mainteam/`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error adding MainTeam member', error);
    throw error;
  }
};

export const removeMainTeamMember = async id => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/mainteam/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error removing MainTeam member:', error);
    throw error;
  }
};

export const fetchSupportTeam = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/supportteam`);
    return response.data;
  } catch (error) {
    console.error('Error fetching SupportTeam:', error);
    throw error;
  }
};

export const updateSupportTeamMember = async (memberId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/supportteam/${memberId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating SupportTeam member:', error);
    throw error;
  }
};

export const addSupportTeamMember = async updatedData => {
  try {
    const response = await axios.post(`${API_BASE_URL}/supportteam/`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating SupportTeam member:', error);
    throw error;
  }
};

export const removeSupportTeamMember = async id => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/supportteam/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error removing SupportTeam member:', error);
    throw error;
  }
};

//http://localhost:3000/api/contact
export const sendContactForm = async formData => {
  try {
    const response = await axios.post(`${API_BASE_URL}/contact`, formData);
    return response.data;
  } catch (error) {
    console.error('Error fetching Contact:', error);
    throw error;
  }
};
