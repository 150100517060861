import React from 'react';
import { useTranslation } from 'react-i18next';

import ProjectImg from '../../images/herstelImg.jpeg';

export const AboutOurProjects = () => {
  const { t } = useTranslation();
  return (
    <div className="content-block text-white bg-main-color" id="link-project">
      <div className="max-w-6xl m-auto w-full">
        <div className="flex lg:flex-row flex-col px-5 text-center lg:text-left gap-x-8">
          <div className="flex flex-col place-content-center">
            <div className="header-text-white">{t('aboutOurProjectsHeader')}</div>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: t('aboutOurProjectsText') }}
            ></div>
          </div>
          <img className="mt-8 lg:mt-0 w-2/4" src={ProjectImg} alt={t('aboutOurProjectsImage')} />
        </div>
      </div>
    </div>
  );
};
