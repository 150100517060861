import React from 'react';
import { ButtonSmall } from '../buttons/ButtonSmall';
import { useTranslation } from 'react-i18next';

export const Project = ({ data }: any) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full px-5 lg:px-0 lg:gap-x-12 py-12 max-h-[350px]">
      <div className="flex flex-col flex-1">
        <div className="header-text mb-2">{data.title}</div>
        <div className="flex-1 text-ellipsis ">
          {data.body ||
            'Korte beschrijving van het project. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit error obcaecati dolorem velit, rem culpa. Beatae, consequuntur suscipit recusandae natus labore culpa accusantium! Labore perferendis incidunt optio, officiis consequatur quo.'}
        </div>
        <div className="mt-6">
          <ButtonSmall text={t('projectButton')} link={`/project/${data._id}`} />
        </div>
      </div>
      {data.img && (
        <div>
          <img
            className="w-[350px] aspect-video overflow-hidden object-cover hidden lg:block"
            src={data.img}
            alt={data.title}
          />
        </div>
      )}
    </div>
  );
};
