import React, { useEffect } from 'react';

export const Admin = () => {
  const [pass, setPass] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem('stko') === 'true') {
      setIsAdmin(true);
    }
  }, []);

  const handleClick = () => {
    if (isAdmin) {
      localStorage.setItem('stko', 'false');
      setIsAdmin(false);
    } else {
      if (pass === 'admin') {
        localStorage.setItem('stko', 'true');
        setIsAdmin(true);
      } else {
        alert('Wachtwoord onjuist');
      }
    }
  };

  return (
    <div className="font-gordita max-w-6xl m-auto min-h-[500px] grid items-center">
      <div>
        <div className="text-2xl text-main-color font-bold">Inloggen als admin</div>
        {isAdmin ? (
          <>
            <div>
              <button
                className="animate bg-main-color hover:bg-main-color/80 active:bg-main-color/60 text-white rounded-lg px-4 pb-1 pt-3 w-full mt-4"
                onClick={() => handleClick()}
              >
                Uitloggen
              </button>
            </div>
          </>
        ) : (
          <>
            <input
              className="text-black rounded-lg pb-.5 pt-1 px-4 w-full border-2 border-gray-300 mt-4 outline-none ring-0 focus:ring-0 focus:border-main-color"
              placeholder={'Wachtwoord'}
              onChange={e => setPass(e.target.value)}
            />
            <div className="mt-4">
              <button
                className="animate bg-main-color hover:bg-main-color/80 active:bg-main-color/60 text-white rounded-lg px-4 pb-1 pt-3 w-full mt-4 disabled:opacity-50"
                disabled={pass.length < 1}
                onClick={() => handleClick()}
              >
                Inloggen
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
