import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BannerImg from './../../images/stko-logo.png';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import '/node_modules/flag-icons/css/flag-icons.min.css';

interface Link {
  text: string;
  link: string;
}

export const Navbar = () => {
  const [currentSection, setCurrentSection] = React.useState('');
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const { t, i18n } = useTranslation();
  const links = t('links', { returnObjects: true }) as Link[];
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const navigate = useNavigate();

  const handleScroll = () => {
    const offset = window.scrollY;
    setIsScrolled(offset > 150);
  };

  const ScrollToComponent = useCallback(
    (component: string, navigateFirst = false) => {
      const scrollToElement = () => {
        const element = document.getElementById(component);
        if (element) {
          setCurrentSection(component);
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      if (navigateFirst) {
        navigate('/#' + component); // Navigate to the main page with the hash
        setTimeout(scrollToElement, 500); // Allow time for navigation and page render
      } else {
        scrollToElement();
      }
    },
    [navigate, setCurrentSection]
  );

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const component = hash.replace('#', '');
      ScrollToComponent(component, true);
    }
  }, [ScrollToComponent]);

  const toggleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setShowMenu(false);
  };

  return (
    <>
      <div className="w-screen sticky inset-0 z-40 bg-white h-fit shadow-lg hidden lg:block">
        <div className="max-w-6xl m-auto flex flex-row justify-between text-lg py-2">
          <div className="w-24 relative">
            <div
              className={`${isScrolled ? 'w-16 h-16 p-1 cursor-pointer' : 'w-32 h-32 p-4 mt-4'} absolute transition-all duration-300 rounded-xl grid place-content-center bg-white border-2 border-white  shadow-xl`}
              onClick={() => ScrollToComponent('main-banner')}
              data-umami-event="Click Logo"
            >
              <img src={BannerImg} alt="STKO Logo" />
            </div>
          </div>
          <div className="py-2 flex gap-x-4">
            {links.map((item: any, id: any) => {
              return (
                <div
                  key={id}
                  className="cursor-pointer"
                  onClick={() => ScrollToComponent(item.link)}
                  data-umami-event="Navigation Click"
                  data-umami-event-section={item.title}
                >
                  <a
                    href={'#' + item.title.toLowerCase()}
                    className={`${currentSection === item.link ? 'text-main-color font-semibold' : 'font-light text-black/60 hover:text-main-color '} transtion-all duration-200`}
                  >
                    {item.title}
                  </a>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-x-2 py-2">
            <div
              className="cursor-pointer flex"
              onClick={() => toggleLanguage('nl')}
              data-umami-event="Switch Language"
              data-umami-event-language="Dutch"
            >
              <span className="fi fi-nl"></span>
            </div>
            <span className="text-black/60">/</span>
            <div
              className="cursor-pointer flex"
              onClick={() => toggleLanguage('en')}
              data-umami-event="Switch Language"
              data-umami-event-language="English"
            >
              <span className="fi fi-gb"></span>
            </div>
            <span className="text-black/60">/</span>
            <div
              className="cursor-pointer flex"
              onClick={() => toggleLanguage('ukr')}
              data-umami-event="Switch Language"
              data-umami-event-language="Ukrainian"
            >
              <span className="fi fi-ua"></span>
            </div>
          </div>
          {/* <div onClick={()=>adminToggle()}>
            admin toggle
          </div> */}
        </div>
      </div>
      <div className="fixed top-0 z-40 w-screen lg:hidden">
        <div
          className="bg-white/70 w-fit ml-auto m-2 rounded-lg"
          onClick={() => toggleMenu()}
          data-umami-event="Toggle Mobile Menu"
        >
          <Bars3Icon className="w-10 h-10 text-main-color" />
        </div>
        <div
          className={`${isScrolled ? 'w-16 h-16 p-1 cursor-pointer' : 'w-32 h-32 p-4 mt-4'} absolute top-2 left-2 transition-all duration-300 rounded-xl grid place-content-center`}
          onClick={() => ScrollToComponent('main-banner')}
        >
          <img src={BannerImg} alt="STKO Logo" />
        </div>
        <div
          className={`fixed inset-0 ${!showMenu ? 'translate-x-[100%]' : 'translate-x-0'} transition-all duration-200 w-screen h-screen bg-white`}
        >
          <div className="bg-white/70 w-fit ml-auto m-2 rounded-lg" onClick={() => toggleMenu()}>
            <Bars3Icon className="w-10 h-10 text-main-color" />
          </div>

          <div>
            <div
              className={`w-44 h-44 p-4 mt-4 m-auto mt-12`}
              onClick={() => {
                ScrollToComponent('main-banner');
                toggleMenu();
              }}
            >
              <img src={BannerImg} alt="STKO Logo" />
            </div>
            <div className="text-center text-2xl font-bold text-main-color mt-8 px-5">
              Stichting Twentse Kinderhulp Oekraine
            </div>
            <div className="m-auto w-fit text-center mt-4">
              {links.map((item: any, id: any) => {
                return (
                  <div
                    key={id}
                    className="cursor-pointer py-2 font-semibold text-lg"
                    onClick={() => {
                      ScrollToComponent(item.link);
                      toggleMenu();
                    }}
                    data-umami-event="Mobile Navigation Click"
                    data-umami-event-section={item.title}
                  >
                    <a
                      href={'#' + item.title.toLowerCase()}
                      className={`${currentSection === item.link ? 'text-main-color font-bold' : 'font-semibold text-black/60 hover:text-main-color '} transtion-all duration-200`}
                    >
                      {item.title}
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row gap-x-2 py-2 w-fit m-auto mt-4">
              <div
                className="cursor-pointer flex"
                onClick={() => toggleLanguage('nl')}
                data-umami-event="Switch Language Mobile"
                data-umami-event-language="Dutch"
              >
                <span className="fi fi-nl"></span>
              </div>
              <span className="text-black/60">/</span>
              <div
                className="cursor-pointer flex"
                onClick={() => toggleLanguage('en')}
                data-umami-event="Switch Language Mobile"
                data-umami-event-language="English"
              >
                <span className="fi fi-gb"></span>
              </div>
              <span className="text-black/60">/</span>
              <div
                className="cursor-pointer flex"
                onClick={() => toggleLanguage('ukr')}
                data-umami-event="Switch Language Mobile"
                data-umami-event-language="Ukrainian"
              >
                <span className="fi fi-ua"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
