import React from 'react';
import { useTranslation } from 'react-i18next';

import WeAreImg from '../../images/mainImage.jpeg';

import HandImg from '../../images/hand.png';
import WorldImg from '../../images/wereld.png';
import BalloonImg from '../../images/ballon.png';

export const WeAre = () => {
  const { t } = useTranslation();
  return (
    <div className="content-block max-w-6xl m-auto text-center lg:text-left px-4" id="link-main">
      <div className="flex gap-x-8">
        <div className="flex flex-col place-content-center">
          <div className="header-text">{t('weAreHeader')}</div>
          <div className="mt-4" dangerouslySetInnerHTML={{ __html: t('weAreText') }}></div>
        </div>
        <img className="hidden lg:block w-2/3" src={WeAreImg} alt={t('weAreMainImage')} />
      </div>
      <div className="overflow-hidden">
        <div className="flex flex-col w-fit m-auto lg:flex-row mt-20 lg:gap-x-20 gap-y-20 lg:gap-y-0">
          <div className="w-[300px] text-center">
            <div className="m-auto w-fit py-4">
              <img className="h-20 w-20" src={HandImg} alt={t('weAreValues1Title')} />
            </div>
            <div className="text-center font-bold text-main-color pb-2">
              {t('weAreValues1Title')}
            </div>
            <div className="max-w-[300px] m-auto">{t('weAreValues1')}</div>
          </div>
          <div className="w-[300px] text-center">
            <div className="m-auto w-fit py-4">
              <img className="h-20 w-20" src={WorldImg} alt={t('weAreValues2Title')} />
            </div>
            <div className="text-center font-bold text-main-color pb-2">
              {t('weAreValues2Title')}
            </div>
            <div className="max-w-[300px] m-auto">{t('weAreValues2')}</div>
          </div>
          <div className="w-[300px] text-center">
            <div className="m-auto w-fit py-4">
              <img className="h-20 w-20" src={BalloonImg} alt={t('weAreValues3Title')} />
            </div>
            <div className="text-center font-bold text-main-color pb-2">
              {t('weAreValues3Title')}
            </div>
            <div className="max-w-[300px] m-auto">{t('weAreValues3')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
