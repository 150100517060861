import React from 'react';
import BannerImage from './../../images/bannerImg.jpg';
import { useTranslation } from 'react-i18next';
import { ButtonLarge } from '../buttons/ButtonLarge';
import { ScrollToComponent } from '../../functions/ScrollToComponent';
import { ButtonSmall } from '../buttons/ButtonSmall';

export const MainBanner = () => {
  const { t } = useTranslation();

  return (
    <div className="" id="main-banner">
      <div className="relative">
        <img className="h-[100vh] object-cover w-full" src={BannerImage} alt={t('bannerImage')} />
        <div className="bg-black/70 backdrop-blur-sm h-screen w-screen absolute inset-0" />
      </div>
      <div className="absolute inset-0 grid place-content-center px-4">
        <div className="text-white font-bold text-4xl lg:text-7xl text-center pb-12 px-2">
          Stichting Twentse Kinderhulp Oekraïne
        </div>
        <div
          className="text-white text-2xl lg:text-4xl text-center px-4"
          dangerouslySetInnerHTML={{ __html: t('slogan') }}
        ></div>
        <div className="m-auto mt-8 " onClick={() => ScrollToComponent('link-contact')}>
          <div className="hidden lg:block">
            <ButtonLarge />
          </div>
          <div className="lg:hidden">
            <ButtonSmall />
          </div>
        </div>
      </div>
    </div>
  );
};
