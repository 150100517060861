import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setStatus('sending');

    try {
      await emailjs.send(
        'service_cti311u', // Get this from EmailJS dashboard
        'template_02pupsu', // Get this from EmailJS dashboard
        formData,
        '29_AmIFehvku2vyM1' // Get this from EmailJS dashboard
      );

      setStatus('success');
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
    }
  };

  return (
    <div className="content-block text-white bg-main-color" id="link-contact">
      <div className="max-w-6xl m-auto w-full px-5 text-center lg:text-left">
        <div className="flex gap-x-8 max-w-2xl">
          <div className="flex flex-col place-content-center">
            <div className="header-text-white">{t('contactUsHeader')}</div>
            <div className="mt-4" dangerouslySetInnerHTML={{ __html: t('contactUsBody') }}></div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col mt-4">
          <input
            className="input-field"
            placeholder={t('name')}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            data-umami-event="Contact Form Input"
            data-umami-event-field="name"
          />
          <input
            className="input-field"
            placeholder={t('email')}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            data-umami-event="Contact Form Input"
            data-umami-event-field="email"
          />
          <input
            className="input-field"
            placeholder={t('phone')}
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            data-umami-event="Contact Form Input"
            data-umami-event-field="phone"
          />
          <textarea
            rows={5}
            className="input-field"
            placeholder={t('message')}
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
            data-umami-event="Contact Form Input"
            data-umami-event-field="message"
          />
          <div className="mt-2 w-fit m-auto">
            <button
              type="submit"
              className="button-small-light"
              disabled={status === 'sending'}
              data-umami-event="Contact Form Submit"
            >
              {status === 'sending' ? t('sending') : t('send')}
            </button>
          </div>
          {status === 'success' && <p className="text-green-400 mt-2">{t('messageSent')}</p>}
          {status === 'error' && <p className="text-red-400 mt-2">{t('messageError')}</p>}
        </form>
      </div>
    </div>
  );
};
