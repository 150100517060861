import React from 'react';
import { useTranslation } from 'react-i18next';

type ButtonProps = {
  text?: string;
  link?: string;
};

//function to navigate to link

export const ButtonSmall = ({ text, link }: ButtonProps) => {
  const { t } = useTranslation();

  const navigate = (link: string) => {
    window.location.href = link;
  };

  return (
    <button
      className="button-small"
      onClick={() => navigate(link || '')}
      data-umami-event={`Click ${text || t('cta')}`}
    >
      {text || t('cta')}
    </button>
  );
};
